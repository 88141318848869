import React from "react";
import _get from "lodash.get";
import { setPolicyBackofficeStatus } from "../../services/PoliciesService";
import withConfirmationModal from "../HOC/withConfirmationModal";
import ConfirmationModal from "../Comments/ConfirmationModal";
import { primary } from "../../utils/colors";
import { Box, Select, MenuItem, FormControl, InputLabel, Checkbox, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
const statusConfig = {
  CSC: {
    //todo да се оправят след промените по новия дизайн за Каското по подобие на GO
    policies: {
      queries: {
        query: ["OfferConfirmed", "OfferSent", "OfferConfirmed", "OfferRejected", "None"],
        offers: ["OfferConfirmed", "OfferSent", "OfferConfirmed", "OfferRejected", "None"],
        confirmed: ["OfferConfirmed", "OfferSent", "OfferConfirmed", "OfferRejected", "None"],
        rejected: ["OfferConfirmed", "OfferSent", "OfferConfirmed", "None"],
        issued: []
      },
      policies: {
        active: [],
        expiring: ["CustomerInformedForExpiring"],
        informed: ["OfferConfirmed", "PaidElsewhere", "Cancelled", "OfferSent", "OfferConfirmed", "None"],
        renewed: ["OfferConfirmed", "PaidElsewhere", "Cancelled", "OfferSent", "OfferConfirmed", "None"],
        cancelled: [],
        all: ["OfferConfirmed", "PaidElsewhere", "Cancelled", "OfferSent", "OfferConfirmed", "None"]
      }
    }
  },

  GO: {
    policies: {
      queries: ["OfferSent", "OfferConfirmed", "OfferRejected", "None"], //"changeStatusPolicy"
      policies: {
        active: [],
        expiring: ["CustomerInformedForExpiring"],
        renewed: ["PaidElsewhere", "Cancelled", "OfferSent", "OfferConfirmed", "OfferRejected", "None"], //"changeStatusPolicy"
        cancelled: ["Active"],
        all: ["PaidElsewhere", "Cancelled", "OfferSent", "OfferConfirmed", "OfferRejected", "None", "Active"] //"changeStatusPolicy"
      }
    }
  },
  PRP: {
    policies: {
      policies: { expiring: ["CustomerInformedForExpiring"] }
    }
  },
  HTL: {
    policies: {
      policies: { expiring: ["CustomerInformedForExpiring"] }
    }
  },
  LFE: {
    policies: {
      queries: {
        query: ["OfferConfirmed", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        confirmed: ["None", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        rejected: ["OfferConfirmed", "None", "SingedByCustomer", "SentToInsurer"],
        signedByCustomer: ["OfferConfirmed", "None", "OfferRejected", "SentToInsurer"],
        sentToInsurer: ["OfferConfirmed", "None", "OfferRejected", "SingedByCustomer"],
        issued: []
      },
      policies: {
        active: []
      }
    }
  },
  HWP: {
    policies: {
      queries: {
        query: ["OfferConfirmed", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        confirmed: ["None", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        rejected: ["OfferConfirmed", "None", "SingedByCustomer", "SentToInsurer"],
        signedByCustomer: ["OfferConfirmed", "None", "OfferRejected", "SentToInsurer"],
        sentToInsurer: ["OfferConfirmed", "None", "OfferRejected", "SingedByCustomer"],
        issued: []
      },
      policies: {
        active: []
      }
    }
  },
  CI: {
    policies: {
      queries: {
        query: ["OfferConfirmed", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        confirmed: ["None", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        rejected: ["OfferConfirmed", "None", "SingedByCustomer", "SentToInsurer"],
        signedByCustomer: ["OfferConfirmed", "None", "OfferRejected", "SentToInsurer"],
        sentToInsurer: ["OfferConfirmed", "None", "OfferRejected", "SingedByCustomer"],
        issued: []
      },
      policies: {
        active: []
      }
    }
  },
  HCL: {
    policies: {
      queries: {
        query: ["OfferConfirmed", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        confirmed: ["None", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        rejected: ["OfferConfirmed", "None", "SingedByCustomer", "SentToInsurer"],
        signedByCustomer: ["OfferConfirmed", "None", "OfferRejected", "SentToInsurer"],
        sentToInsurer: ["OfferConfirmed", "None", "OfferRejected", "SingedByCustomer"],
        issued: []
      },
      policies: {
        active: []
      }
    }
  },
  CHCL: {
    policies: {
      queries: {
        query: ["OfferConfirmed", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        confirmed: ["None", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        rejected: ["OfferConfirmed", "None", "SingedByCustomer", "SentToInsurer"],
        signedByCustomer: ["OfferConfirmed", "None", "OfferRejected", "SentToInsurer"],
        sentToInsurer: ["OfferConfirmed", "None", "OfferRejected", "SingedByCustomer"],
        issued: []
      },
      policies: {
        active: []
      }
    }
  },
  HCEF: {
    policies: {
      queries: {
        query: ["OfferConfirmed", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        confirmed: ["None", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        rejected: ["OfferConfirmed", "None", "SingedByCustomer", "SentToInsurer"],
        signedByCustomer: ["OfferConfirmed", "None", "OfferRejected", "SentToInsurer"],
        sentToInsurer: ["OfferConfirmed", "None", "OfferRejected", "SingedByCustomer"],
        issued: []
      },
      policies: {
        active: []
      }
    }
  },
  LFI: {
    policies: {
      queries: {
        query: ["OfferConfirmed", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        confirmed: ["None", "OfferRejected", "SingedByCustomer", "SentToInsurer"],
        rejected: ["OfferConfirmed", "None", "SingedByCustomer", "SentToInsurer"],
        signedByCustomer: ["OfferConfirmed", "None", "OfferRejected", "SentToInsurer"],
        sentToInsurer: ["OfferConfirmed", "None", "OfferRejected", "SingedByCustomer"],
        issued: []
      },
      policies: {
        active: []
      }
    }
  },
  ["CSC+GO"]: {
    CSC: {
      policies: {
        queries: ["OfferSent", "OfferConfirmed", "OfferRejected", "None"],
        offers: {
          query: ["OfferSent", "OfferConfirmed", "OfferRejected", "None"],
          offers: ["OfferSent", "OfferConfirmed", "OfferRejected", "None"],
          confirmed: ["OfferSent", "OfferConfirmed", "OfferRejected", "None"],
          rejected: ["OfferSent", "OfferConfirmed", "None"]
        },
        policies: {
          active: [],
          expiring: ["CustomerInformedForExpiring"],
          informed: ["PaidElsewhere", "Cancelled", "OfferSent", "OfferConfirmed", "None"],
          renewed: ["PaidElsewhere", "Cancelled", "OfferSent", "OfferConfirmed", "None"],
          cancelled: [],
          all: ["PaidElsewhere", "Cancelled", "OfferSent", "OfferConfirmed", "None"]
        }
      }
    },

    GO: {
      policies: {
        queries: ["OfferSent", "OfferConfirmed", "OfferRejected", "None"],
        offers: {
          query: ["OfferSent", "OfferConfirmed", "OfferRejected", "None"],
          offers: ["OfferSent", "OfferConfirmed", "OfferRejected", "None"],
          confirmed: ["OfferSent", "OfferConfirmed", "OfferRejected", "None"],
          rejected: ["OfferSent", "OfferConfirmed", "None"]
        },
        policies: {
          active: [],
          expiring: ["CustomerInformedForExpiring"],
          renewed: ["PaidElsewhere", "Cancelled", "OfferSent", "OfferConfirmed", "OfferRejected", "None"], //"changeStatusPolicy"
          cancelled: [],
          all: ["PaidElsewhere", "Cancelled", "OfferSent", "OfferConfirmed", "OfferRejected", "None"] //"changeStatusPolicy"
        }
      }
    }
  }
};

const isStatusVisible = (actionId, productId, variant, subVariant, currentProduct) => {
  const type = "policies";
  const configArr = _get(statusConfig, `${currentProduct === "CSC+GO" ? `${currentProduct}.` : ""}${productId}.${type}.${variant}${subVariant ? `.${subVariant}` : ""}`, []);
  return configArr.includes(actionId);
};
class ChangeStatusPolicyConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: "", email: _get(props, "objWithEgnAndRequestId.email", ""), sentTo: false };
  }

  handleTxtChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    });
  };
  handleCheckbox = event => {
    const { checked, name } = event.target;
    this.setState({
      [name]: checked
    });
  };
  render() {
    const {
      modalName,
      isChangeStatusPolicyModalOpen,
      toggle,
      objWithEgnAndRequestId,
      egn,
      handle,
      showEgnCheck,
      egnValidationError,
      onChange,
      variant,
      subVariant,
      currentProduct
    } = this.props;
    const modalTitle = "Промяна на бекофис статуса на полицата";
    const btnTxt = "Промени";
    const { status, email, sentTo } = this.state;
    const productId = _get(objWithEgnAndRequestId, "productId");
    const isVisible = actionId => isStatusVisible(actionId, productId, variant, subVariant, currentProduct);
    const txtConfirmed = (p => {
      if (p === "CSC") return "Платена";
      if (p === "GO") return "Платена";
      if (p === "LFE") return "Одобрена";
      return "Одобрена";
    })(productId);
    const txtNone = (p => {
      if (p === "LFE") return "Обработва се";
      return "Заявка";
    })(productId);
    return (
      <ConfirmationModal
        modalName={modalName}
        modalTitle={modalTitle}
        btnColor={primary}
        btnTxt={btnTxt}
        isModalOpen={isChangeStatusPolicyModalOpen}
        toggle={toggle}
        objWithEgnAndRequestId={{ ...objWithEgnAndRequestId, status, email, sentTo }}
        egn={egn}
        handle={handle}
        showEgnCheck={showEgnCheck}
        egnValidationError={egnValidationError}
        onChange={onChange}
      >
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Статус
            </InputLabel>
            <Select
              SelectDisplayProps={{
                style: { fontSize: 16 }
              }}
              labelId="demo-simple-select-placeholder-label-label"
              id="outlined-size-small"
              value={status}
              onChange={e => this.handleTxtChange(e, "status")}
              displayEmpty
            >
              {isVisible("PaidElsewhere") && <MenuItem value={"PaidElsewhere"}>Платена на друго място</MenuItem>}
              {isVisible("Cancelled") && <MenuItem value={"Cancelled"}>Полицата е прекратена</MenuItem>}
              {isVisible("OfferSent") && <MenuItem value={"OfferSent"}>Изпратена оферта</MenuItem>}
              {isVisible("OfferConfirmed") && <MenuItem value={"OfferConfirmed"}>{txtConfirmed}</MenuItem>}
              {isVisible("OfferRejected") && <MenuItem value={"OfferRejected"}>Отказана</MenuItem>}
              {isVisible("SingedByCustomer") && <MenuItem value={"SingedByCustomer"}>Подписана от клиента</MenuItem>}
              {isVisible("SentToInsurer") && <MenuItem value={"SentToInsurer"}>Изпратена на застрахователя</MenuItem>}
              {isVisible("None") && <MenuItem value={"None"}>{txtNone}</MenuItem>}
              {isVisible("Active") && <MenuItem value={"Active"}>Активна</MenuItem>}
              {isVisible("CustomerInformedForExpiring") && <MenuItem value={"CustomerInformedForExpiring"}>Клиентът е информиран</MenuItem>}
            </Select>
            {["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"].includes(productId) && ["OfferRejected"].includes(status) && (
              <>
                <FormControlLabel label="Изпращане на имейл до клиента" control={<Checkbox name="sentTo" checked={sentTo} onChange={e => this.handleCheckbox(e)} />} />
                {!!sentTo && (
                  <TextField
                    sx={{ mb: 1, mt: 1 }}
                    placeholder="kristian@boleron.com"
                    fullWidth
                    label="ИМЕЙЛ НА КЛИЕНТ"
                    variant="outlined"
                    size="small"
                    name="email"
                    value={email}
                    onChange={e => this.handleTxtChange(e, "email")}
                  />
                )}
              </>
            )}
          </FormControl>
        </Box>
      </ConfirmationModal>
    );
  }
}

const generatePayloadRequest = props => {
  const requestPayload = {
    policyId: _get(props, "objWithEgnAndRequestId._id"),
    status: _get(props, "objWithEgnAndRequestId.status"),
    email: _get(props, "objWithEgnAndRequestId.email"),
    sentTo: _get(props, "objWithEgnAndRequestId.sentTo")
  };
  return requestPayload;
};

export default withConfirmationModal(ChangeStatusPolicyConfirmationModal, {
  generatePayloadRequest,
  apiRequest: setPolicyBackofficeStatus,
  modalName: "changeStatusPolicyModal",
  successMessage: "Успешна промяна!"
});
