import React from "react";
import { Box } from "@mui/material";
import _get from "lodash.get";

export const vehicleTypes = [
  { value: "", label: "Избери", en: "Select", levinsCode: -1, dziCode: -1 },
  { value: "car", label: "Лек автомобил", en: "Car", levinsCode: 1, dziCode: 1 },
  { value: "truck", label: "Товарен автомобил", en: "Freight vehicle", levinsCode: 3, dziCode: 3 },
  { value: "motorcycle", label: "Мотоциклет", en: "Motocycle", levinsCode: 2, dziCode: 4 },
  { value: "moped", label: "Мотопед", en: "Moped", levinsCode: 2, dziCode: 5 }
];

export const engines = [
  { bulstrad: "", audatex: "", code: -1 },
  { bulstrad: "B", audatex: "Бензинов", label: "key24382", code: 0 },
  { bulstrad: "D", audatex: "Дизелов", label: "key24383", code: 1 },
  { bulstrad: "E", audatex: "Електрически", label: "Electric", code: 2 },
  { bulstrad: "G", audatex: "Газ (LPG)", label: "LPG", code: 3 },
  { bulstrad: "H", audatex: "Хибриден", label: "Hybrid", code: 4 }
];
export const gearboxes = [
  { bulstrad: "", audatex: "", code: -1 },
  { bulstrad: "Ръчни", audatex: "Ръчни", label: "Manual", code: 0 },
  { bulstrad: "Автоматични", audatex: "Автоматични", label: "Automatic", code: 1 }
];

const Row = ({ label, value }) => {
  return (
    <div>
      <b>{label}&nbsp;</b>
      <span>{value}</span>
    </div>
  );
};

const UnregisteredVehicleData = props => {
  const productId = _get(props, "policy.productId");
  const carMake = _get(props, "policy.policyData.make") || _get(props, "policy.policyData.cscMake");
  const carModel = _get(props, "policy.policyData.model") || _get(props, "policy.policyData.cscModel");
  const vin = _get(props, "policy.policyData.vinNumber");

  const engineVolume = _get(props, "policy.policyData.cscEngineDisplacement");
  const kW = _get(props, "policy.policyData.cscPower");
  const buildYear = _get(props, "policy.policyData.cscBuildYear");
  const engine = engines.find(x => x.bulstrad === _get(props, "policy.policyData.cscEngine"))?.audatex || {};
  const gearbox = gearboxes.find(x => x.bulstrad === _get(props, "policy.policyData.cscGearbox"))?.audatex || {};
  const bodyType = _get(props, "policy.policyData.cscBodyType");
  const vehicleType = vehicleTypes.find(vt => vt.value === _get(props, "policy.policyData.cscVehicleType"))?.label;

  const persons = _get(props, "policy.policyData.persons", []);
  const insuredPerson = persons?.find(p => p?.isInsured === true);
  const ownerName = `${insuredPerson?.firstName} ${insuredPerson?.middleName} ${insuredPerson?.lastName}`;
  const eik = _get(props, "policy.policyData.eik");
  const companyName = _get(props, "policy.policyData.companyName");
  const address = _get(props, "policy.policyData.address");
  const city = _get(props, "policy.policyData.city");

  return (
    <>
      {["CSC", "GO"].includes(productId) && (
        <>
          <Box mt={3}>
            <Row label="Марка:" value={carMake} />
            <Row label="Модел:" value={carModel} />
            <Row label="VIN:" value={vin} />
            <Row label="Обем на двигателя:" value={engineVolume} />
            <Row label="Мощност kW:" value={kW} />
            {["CSC"].includes(productId) && (
              <>
                <Row label="Скорости:" value={gearbox} />
                <Row label="Вид купе:" value={bodyType} />
              </>
            )}
            <Row label="Година на производство:" value={buildYear} />
            <Row label="Вид гориво:" value={engine} />
            {["GO"].includes(productId) && <Row label="Вид на автомобила:" value={vehicleType} />}
            {insuredPerson && (
              <>
                <Row label="Собственик:" value={ownerName} />
                <Row label="ЕГН:" value={insuredPerson?.egn} />
              </>
            )}
            {eik && (
              <>
                <Row label="Собственик:" value={ownerName} />
                <Row label="ЕИК:" value={companyName} />
              </>
            )}
            <Row label="Адрес:" value={address} />
            <Row label="Населено място:" value={city} />
          </Box>
        </>
      )}
    </>
  );
};

export default UnregisteredVehicleData;
