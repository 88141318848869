export const sendDocumentsFields = [
  {
    name: "email",
    insurer: "metlife",
    label: "ИМЕЙЛИ НА ЗАСТРАХОВАТЕЛЯ",
    placeholder: "metlife@boleron.com, metlife1@boleron.com, metlife2@boleron.com ...",
    validators: ["required", "validateEmailsSeparatedByComma"],
    errorMessages: ["Полето е задължително", "Невалиден списък с имейл адреси"],
    value:
      process.env.REACT_APP_ENV === "production"
        ? "nina.tsankova@metlife.bg, pandora.m.georgieva@metlife.bg, deyana.z.belenkov@metlife.bg, denitsa.i.draganova@metlife.bg, vanina.s.yanachkova@metlife.bg, brokers@boleron.com"
        : "",
    multiline: true,
    rows: 3
  },
  {
    name: "email",
    insurer: "bulstradlife",
    products: ["HCEF"],
    label: "ИМЕЙЛИ НА ЗАСТРАХОВАТЕЛЯ",
    placeholder: "bulstrad@boleron.com, bulstrad1@boleron.com, bulstrad2@boleron.com ...",
    validators: ["required", "validateEmailsSeparatedByComma"],
    errorMessages: ["Полето е задължително", "Невалиден списък с имейл адреси"],
    value: process.env.REACT_APP_ENV === "production" ? "health_ins@bulstradlife.bg, t_ivanov@bulstradlife.bg, brokers@boleron.com" : "",
    multiline: true,
    rows: 3
  },
  {
    name: "email",
    insurer: "bulstradlife",
    products: ["HCL", "CHCL"],
    label: "ИМЕЙЛИ НА ЗАСТРАХОВАТЕЛЯ",
    placeholder: "bulstrad@boleron.com, bulstrad1@boleron.com, bulstrad2@boleron.com ...",
    validators: ["required", "validateEmailsSeparatedByComma"],
    errorMessages: ["Полето е задължително", "Невалиден списък с имейл адреси"],
    value: process.env.REACT_APP_ENV === "production" ? "affinity@bulstradlife.bg, brokers@boleron.com" : "",
    multiline: true,
    rows: 3
  }
];
