import React from "react";
import { ADDITONAL_PDF_SERVICE_URL, PDF_SERVICE_URL } from "../../utils/config";
import { danger, gray, primary, success } from "../../utils/colors";
import { getAdditionalPolicyFiles } from "../../services/PoliciesService";
import _get from "lodash.get";
import { downloadPdfByPolicyId } from "../../services/Shipment/Shipment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { displayFileName } from "../SendDocuments/SendDocuments";

class Files extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      policyOid: _get(props, "policy._id", ""),
      isPending: props.policy.isPending,
      files: [],
      receiptFileExists: false,
      vDogFileExists: false,
      policyFileExists: false
    };
  }
  sortAdditionalFiles = files => {
    const compareFn = (a, b) => {
      // Check for "receipt" and "vDog" to move them to the end
      const typeOrder = {
        saleAgreement: 1,
        technicalCharacteristics: 1,
        receipt: 1,
        vdog: 1
      };

      const aIsSpecial = typeOrder[a.type] || 0;
      const bIsSpecial = typeOrder[b.type] || 0;

      // If one is special and the other is not, prioritize the non-special
      if (aIsSpecial !== bIsSpecial) return aIsSpecial - bIsSpecial;

      // If both are non-special or both are special, sort by numerical prefix
      if (a.name === undefined || b.name === undefined) {
        return 1;
      }
      a = /^[\d]+$/.exec(a.name.split(".")[0]);
      b = /^[\d]+$/.exec(b.name.split(".")[0]);
      if (a && a[0] && b && b[0]) {
        const indexA = parseInt(a[0]);
        const indexB = parseInt(b[0]);
        if (indexA < indexB) {
          return -1;
        } else if (indexA > indexB) {
          return 1;
        } else {
          return 0;
        }
      }
      return 1;
    };
    return files.sort(compareFn);
  };

  componentDidMount = async () => {
    const { policyOid } = this.state;
    const response = (await getAdditionalPolicyFiles(policyOid)) || {};
    const policyAllFiles = response.data || [];
    const receiptFileExists = policyAllFiles.some(object => object.type === "receipt");
    const vDogFileExists = policyAllFiles.some(object => object.type === "vdog");
    const policyFileExists = policyAllFiles.some(object => object.type === "policy");
    const files = this.sortAdditionalFiles([...policyAllFiles]);
    this.setState({ files, receiptFileExists, vDogFileExists, policyFileExists });
  };

  checkFileExtAndReturnLink = (file, policy, uid) => {
    const fileName = file.name;
    const fileType = file.type;

    const re = /(?:\.([^.]+))?$/;
    let ext = re.exec(fileName)[1];

    return ((extension, type) => {
      if (extension === "xlsx") {
        return `${ADDITONAL_PDF_SERVICE_URL}/${policy._id}/${fileName}`;
      }
      if (type === "policy") return `${PDF_SERVICE_URL}/${policy._id}/policy`;
      if (["receipt", "vdog", "identification"].includes(type)) return `${PDF_SERVICE_URL}/${policy._id}/${file.type}/${uid}`;
      if (type === "birthCertificate") return `${PDF_SERVICE_URL}/${file.name}/birthCertificate`;
      if (["saleAgreement", "technicalCharacteristics"].includes(type)) return `${PDF_SERVICE_URL}/${file.policyDBId}/${type}`;
      return `${ADDITONAL_PDF_SERVICE_URL}/${policy._id}/${fileName}/${uid}`;
    })(ext, fileType);
  };

  render() {
    const { policy, uid } = this.props;
    const { files, receiptFileExists, vDogFileExists, policyFileExists } = this.state;
    const fileConditions = [
      { exists: receiptFileExists, label: "Разписка" },
      { exists: vDogFileExists, label: "Възлагателен договор" },
      { exists: policyFileExists, label: "Полица" }
    ];

    const pdfIcon = <PictureAsPdfIcon sx={{ mr: 1 }} />;
    return (
      <>
        {files.length
          ? files.map((file, index) => {
              if (file.type !== "shipment") {
                return (
                  <div className="additionalFiles vertical-align" key={`additionaFile_${index}`}>
                    {pdfIcon}
                    <a
                      style={{ color: file.hidden ? gray : primary }}
                      href={this.checkFileExtAndReturnLink(file, policy, uid)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="document-link"
                    >
                      {displayFileName(file)}
                    </a>
                  </div>
                );
              } else {
                return (
                  <div className="additionalFiles vertical-align" key={`shipment_${index}`}>
                    {pdfIcon}
                    <span
                      style={{ color: primary }}
                      onClick={() => downloadPdfByPolicyId({ policyId: file?.shipment?.policyId, requestId: file?.shipment?.requestId, courier: file?.shipment?.requestId })}
                      className="document-link"
                    >
                      {file?.name}
                    </span>
                  </div>
                );
              }
            })
          : null}

        {fileConditions
          .filter(file => !file.exists)
          .map((file, index) => (
            <div key={index} className="additionalFiles vertical-align">
              {pdfIcon}
              <p style={{ color: danger, textDecoration: "line-through", margin: 0 }}>{file.label}</p>
            </div>
          ))}
      </>
    );
  }
}

export default Files;
