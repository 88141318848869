import React, { useEffect, useState } from "react";
import * as functions from "../../utils/functions";
import _get from "lodash.get";
import { capitalizeFirstLetter } from "../../utils/helpers";
import { insuranceDetailsFields } from "../../config/WizardOFF/InsuranceDetails.page.config";
import { Button, Checkbox, FormControl, FormHelperText, FormLabel, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { gray, grayBorder, grayLight, white } from "../../utils/colors";
import _find from "lodash.find";

const withValidation = (PassedComponent, data) => {
  return props => {
    const { txtFields, modalName = "" } = data || props;
    let formD = {};
    let errorsD = {};
    txtFields
      .filter(f => !f.hidden?.(props))
      .forEach(tf => {
        formD[tf.name] = tf.value || "";
        if (tf.defaultValueFunction) {
          formD[tf.name] = tf.defaultValueFunction(props, tf.name);
        }
        if (tf.defaultErrorFunction) {
          errorsD[tf.name] = tf.defaultErrorFunction(props, tf.name);
        }
        if (tf.type === "dateRange") {
          if (tf.defaultValueFunction) {
            formD[tf.name] = tf.defaultValueFunction(props);
          } else {
            formD[tf.name] = {};
            (tf?.options || []).forEach(o => {
              formD[tf.name][o.name] = null;
            });
          }
        }
      });
    const [errors, setErrors] = useState(errorsD);
    const [formData, setFormData] = useState(formD);
    useEffect(() => {
      setFormData(formD);
      setErrors(errorsD);
    }, [props.txtFields, props[`is${capitalizeFirstLetter(modalName)}Open`]]);
    const validatorFunction = name => {
      const field = txtFields.find(obj => obj.name === name);
      const { validators } = field;
      const { errorMessages } = field;
      let error = {};
      const n = validators?.length;
      let v;
      for (let index = 0; index < n; index++) {
        v = validators[index];
        if (functions[v](formData[name], { ...props, formData }) !== true) {
          if (typeof errorMessages[index] === "function") {
            error = { [name]: errorMessages[index]({ ...props, selectedFiles }) };
          } else {
            error = { [name]: errorMessages[index] };
          }

          break;
        }
      }
      return error;
    };
    const handleChange = event => {
      const { value, name } = event.target;
      const newFormData = formData;
      const newErrors = { ...errors };
      newErrors[name] = "";
      newFormData[name] = value;
      setFormData(newFormData);
      setErrors(newErrors);
    };
    const handleDateChange = (name, value) => {
      handleChange({ target: { value, name } });
    };
    const handleCheckbox = event => {
      const { checked, name } = event.target;
      const newFormData = formData;
      const newErrors = { ...errors };
      newErrors[name] = "";
      newFormData[name] = checked;
      setFormData(newFormData);
      setErrors(newErrors);
    };
    const handleDateRangeChange = (name, subname, value) => {
      const newFormData = formData;
      newFormData[name] = {
        ...newFormData[name],
        [subname]: value
      };
      const newErrors = { ...errors };
      newErrors[name] = "";
      setFormData(newFormData);
      setErrors(newErrors);
    };

    const handlePercentageChange = event => {
      let value = event.target.value;
      const { name } = event.target;
      value = parseInt(value ? value : 0);
      const newFormData = formData;
      const newErrors = { ...errors };
      newErrors[name] = "";
      newFormData[name] = value >= 0 && value <= 100 ? value : formData[name];
      setFormData(newFormData);
      setErrors(newErrors);
    };
    const handleFileChange = event => {
      const { name, files } = event.target;
      const newFormData = formData;
      const newErrors = { ...errors };
      if (_get(files, "[0].size") > 1024 * 1024 * 10) {
        newFormData[name] = "";
        newErrors[name] = "Размерът на файла не може да е по-голям от 10 MB.";
      } else {
        newErrors[name] = "";
        newFormData[name] = files[0];
      }
      setFormData(newFormData);
      setErrors(newErrors);
    };
    const handleValidation = () => {
      let newErrors = {};
      txtFields
        .filter(f => !f.hidden?.({ ...props, formData }))
        .forEach(field => {
          const error = validatorFunction(field.name, formData);
          if (error) {
            newErrors = { ...newErrors, ...error };
          }
        });
      setErrors(newErrors);
      return !Object.keys(newErrors).length; // isFormValid
    };

    const outerErrorsSet = errors => {
      setErrors(errors);
    };

    // removes hidden fields from FormData
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key]) =>
        txtFields
          .filter(f => !f.hidden?.({ ...props, formData }))
          .map(f => f.name)
          .includes(key)
      )
    );
    //third parameter uploadedFiles is valid only for case: "file"
    const renderFields = (data, fields, uploadedFiles = []) => {
      return (
        <React.Fragment>
          {fields?.map(f => {
            switch (f.type) {
              case "text":
                return (
                  <TextField
                    sx={{ mb: 1, mt: 1 }}
                    key={f.name}
                    placeholder={f.placeholder}
                    fullWidth
                    label={f.label}
                    variant="outlined"
                    size="small"
                    name={f.name}
                    value={data?.formData[f.name]}
                    onChange={data?.handleChange}
                    helperText={data?.errors[f.name]}
                    error={!!data?.errors[f.name]}
                    disabled={f?.readOnly}
                  />
                );
              case "select":
                return (
                  <FormControl fullWidth key={f.name} error={!!data?.errors[f.name]} sx={{ mb: 1, mt: 1 }}>
                    <InputLabel>{f.label}</InputLabel>
                    <Select
                      value={data?.formData[f.name]}
                      name={f.name}
                      onChange={e => data?.handleChange(e)}
                      sx={{ mt: 1 }}
                      size="small"
                      MenuProps={{ sx: { maxHeight: 300 } }}
                      SelectDisplayProps={{
                        style: { fontSize: 16 }
                      }}
                      labelId="demo-simple-select-placeholder-label-label"
                      id="outlined-size-small"
                      displayEmpty
                      readOnly={f?.readOnly}
                    >
                      {(f.options || []).map(o => (
                        <MenuItem key={o.value} value={o.value}>
                          {o.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!data?.errors[f.name] && <FormHelperText>{data?.errors[f.name]}</FormHelperText>}
                  </FormControl>
                );
              case "radio":
                return (
                  <FormControl fullWidth key={f.name}>
                    <RadioGroup className="vertical-align" row aria-labelledby="row-radio-buttons" name={f.name} value={data?.formData[f.name]}>
                      <FormLabel id="row-radio-buttons">{f.label}</FormLabel>
                      {(f.options || []).map(o => (
                        <FormControlLabel key={o.value} value={o.value} control={<Radio onChange={data?.handleChange} />} label={o.label} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                );
              case "dateRange":
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs} key={f.name}>
                    <FormLabel>{f.label}</FormLabel>
                    {(f.options || []).map((o, index) => (
                      <DesktopDatePicker
                        // disableMaskedInput
                        key={o.name}
                        sx={{ mr: 2 }}
                        label={o.label}
                        value={data?.formData[f.name][o.name]}
                        onChange={newValue => data?.handleDateRangeChange(f.name, o.name, newValue)}
                        inputFormat="DD/MM/YYYY"
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={!!data?.errors[f.name]}
                            style={{ marginRight: index === 1 ? 0 : 5, marginBottom: 5, backgroundColor: white, maxWidth: "49%" }}
                            size="small"
                          />
                        )}
                      />
                    ))}
                    {!!data?.errors[f.name] && (
                      <p className="red size-6" style={{ margin: "4px 14px 0 14px" }}>
                        {data?.errors[f.name]}
                      </p>
                    )}
                  </LocalizationProvider>
                );
              case "date":
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs} key={f.name}>
                    <DesktopDatePicker
                      key={f.name}
                      name={f.name}
                      sx={{ mr: 2 }}
                      label={f.label}
                      value={data?.formData[f.name]}
                      onChange={newValue => handleDateChange(f.name, newValue)}
                      inputFormat="DD/MM/YYYY"
                      renderInput={params => <TextField {...params} error={!!data?.errors[f.name]} style={{ marginBottom: 5, backgroundColor: white }} size="small" />}
                    />
                  </LocalizationProvider>
                );
              case "checkbox":
                return (
                  <FormControlLabel
                    key={f.name}
                    label={f.label}
                    // label="Полицата е включена в репортите"
                    control={<Checkbox name={f.name} checked={data?.formData[f.name]} onChange={data?.handleCheckbox} />}
                  />
                );
              case "file":
                return (
                  <div key={f.name}>
                    <InputLabel
                      sx={{
                        fontSize: ".7rem",
                        marginLeft: "15px"
                      }}
                    >
                      {f.label}
                    </InputLabel>
                    <TextField
                      label=""
                      id="outlined-start-adornment"
                      readOnly
                      style={{ width: "100%", marginBottom: "16px" }}
                      value={data?.formData[f.name]?.name}
                      placeholder=""
                      disabled={_get(_find(uploadedFiles, { key: f.name }), "uploaded", false)}
                      inputProps={{
                        style: {
                          padding: 9,
                          color: gray
                        }
                      }}
                      FormHelperTextProps={{
                        className: _get(_find(uploadedFiles, { key: f.name }), "uploaded", false) ? "has-text-success" : "has-text-danger"
                      }}
                      helperText={_get(_find(uploadedFiles, { key: f.name }), "uploaded", false) ? "качено" : data?.errors[f.name]}
                      error={!!data?.errors[f.name]}
                      InputProps={{
                        sx: {
                          padding: 0
                        },
                        endAdornment: (
                          <InputAdornment sx={{ height: "100%" }} position="end">
                            <Button
                              variant="contained"
                              color="inherit"
                              component="label"
                              disabled={f?.readOnly || f?.disabled || _get(_find(uploadedFiles, { key: f.name }), "uploaded", false)}
                              sx={{
                                ml: 1,
                                py: "8px",
                                backgroundColor: grayLight,
                                boxShadow: "none",
                                borderLeft: `1px solid ${grayBorder}`
                              }}
                            >
                              Прикачи
                              <input type="file" hidden name={f.name} onChange={data?.handleFileChange} />
                            </Button>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                );
            }
          })}
        </React.Fragment>
      );
    };
    return (
      <PassedComponent
        {...props}
        handleChange={handleChange}
        handleValidation={handleValidation}
        handleDateRangeChange={handleDateRangeChange}
        handleDateChange={handleDateChange}
        handlePercentageChange={handlePercentageChange}
        handleFileChange={handleFileChange}
        handleCheckbox={handleCheckbox}
        formData={filteredFormData}
        errors={errors}
        outerErrorsSet={outerErrorsSet}
        renderFields={renderFields}
      />
    );
  };
};

export default withValidation;
