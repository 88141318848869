import React, { Component } from "react";
import _get from "lodash.get";
import { PDF_SERVICE_URL } from "../../utils/config";
import { getVehicleData } from "../../services/PoliciesService";
import PhotoCameraBackOutlinedIcon from "@mui/icons-material/PhotoCameraBackOutlined";
import { primary } from "../../utils/colors";

class TalonInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleOid: ""
    };
  }
  componentDidMount = async () => {
    await this.getVehicleId();
  };
  getVehicleId = async () => {
    try {
      const productId = _get(this.props, "policy.productId");
      let carNo;
      let talonNo;
      if (productId === "CSC") {
        carNo = _get(this.props, "policy.policyData.cscRegNumber", "");
        talonNo = _get(this.props, "policy.policyData.cscTalon", "");
      } else if (productId === "GO") {
        carNo = _get(this.props, "policy.policyData.goRegNumber", "");
        talonNo = _get(this.props, "policy.policyData.goTalon", "");
      }
      if (carNo && talonNo) {
        const response = await getVehicleData({
          carNo,
          talonNo
        });
        this.setState({
          vehicleOid: _get(response, "data.vehicleDBId", "")
        });
      }
    } catch (error) {
      console.log(_get(error, "response.data", ""));
    }
  };
  render() {
    const { policy, uid } = this.props;
    const { vehicleOid } = this.state;
    const isOffer = _get(policy, "policyData.cscIsOffer");
    const isIncomplete = _get(policy, "policyData.savedCar.incompleteKATData", false);
    const productId = _get(policy, "productId");
    const photoIcon = <PhotoCameraBackOutlinedIcon sx={{ mr: 1 }} />;
    return (
      (productId === "CSC" || (productId === "GO" && isIncomplete)) && (
        <div>
          {_get(policy, "policyData.bigFile", "") && vehicleOid ? (
            <div className="vertical-align">
              {photoIcon}
              <p>
                <a style={{ color: primary }} href={`${PDF_SERVICE_URL}/${vehicleOid}/big/${uid}`} target="_blank" rel="noopener noreferrer">
                  голям талон
                </a>
              </p>
            </div>
          ) : null}
          {_get(policy, "policyData.frontFile", "") && vehicleOid ? (
            <div className="vertical-align">
              {photoIcon}
              <p>
                <a style={{ color: primary }} href={`${PDF_SERVICE_URL}/${vehicleOid}/smallfront/${uid}`} target="_blank" rel="noopener noreferrer">
                  лице - малък талон
                </a>
              </p>
            </div>
          ) : null}
          {_get(policy, "policyData.backFile", "") && vehicleOid ? (
            <div className="vertical-align">
              {photoIcon}
              <p>
                <a style={{ color: primary }} href={`${PDF_SERVICE_URL}/${vehicleOid}/smallback/${uid}`} target="_blank" rel="noopener noreferrer">
                  гръб - малък талон
                </a>
              </p>
            </div>
          ) : null}
        </div>
      )
    );
  }
}

export default TalonInfo;
