import React from "react";
import { Grid, Icon } from "@mui/material";
import "./backoffice.status.sass";
import { isPolicyGOUnregisteredVehicleAndOffer } from "../../../utils/helpers";

const BackofficeStatus = ({ productId, status, variant, policy }) => {
  const txtNone = (p => {
    if (["CSC", "LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"].includes(p)) return "Обработва се";
    if (p === "GO" && isPolicyGOUnregisteredVehicleAndOffer(policy)) return "Обработва се";
    if (p === "GO") return "За издаване";
    return "";
  })(productId);

  const txtConfirmed = (p => {
    if (["CSC", "CSC+GO", "GO"].includes(p)) return "Платена";
    if (["LFE", "HWP", "CI", "LFI", "HCL", "CHCL", "HCEF"].includes(p)) return "Одобрена";
    return "";
  })(productId);

  switch (status) {
    case "CustomerInformedForExpiring":
      return (
        <Grid container alignItems="center" justifyContent="flex-start" direction="row">
          <Icon className="backoffice-status-icon info">circle</Icon> <span className="backoffice-status-text info">Информиран</span>
        </Grid>
      );
    case "PaidElsewhere":
      return (
        <Grid container alignItems="center" justifyContent="flex-start" direction="row">
          <Icon className="backoffice-status-icon danger">circle</Icon> <span className="backoffice-status-text">Платена другаде</span>
        </Grid>
      );
    case "Cancelled":
      return;
    case "OfferRejected":
    case "offerstatusrejected":
      return (
        <Grid container alignItems="center" justifyContent="flex-start" direction="row">
          <Icon className="backoffice-status-icon danger">circle</Icon> <span className="backoffice-status-text danger">Отказана</span>
        </Grid>
      );
    case "OfferSent":
    case "offerstatusclientoffered":
      return (
        <Grid container alignItems="center" justifyContent="flex-start" direction="row">
          <Icon className="backoffice-status-icon primary">circle</Icon> <span className="backoffice-status-text primary">Изпратена оферта</span>
        </Grid>
      );
    case "OfferConfirmed":
    case "offerstatusconfirmed":
      return (
        <Grid container alignItems="center" justifyContent="flex-start" direction="row">
          <Icon className="backoffice-status-icon success">circle</Icon> <span className="backoffice-status-text success">{txtConfirmed}</span>
        </Grid>
      );
    case "SentToInsurer":
      return (
        <Grid container alignItems="center" justifyContent="flex-start" direction="row">
          <Icon className="backoffice-status-icon primary">circle</Icon> <span className="backoffice-status-text primary">Изпратена</span>
        </Grid>
      );
    case "SingedByCustomer":
      return (
        <Grid container alignItems="center" justifyContent="flex-start" direction="row">
          <Icon className="backoffice-status-icon success size-big">check</Icon> <span className="backoffice-status-text success">Подписана</span>
        </Grid>
      );
    case "PartiallyPaid":
      return (
        <Grid container alignItems="center" justifyContent="flex-start" direction="row">
          <Icon className="backoffice-status-icon danger">circle</Icon> <span className="backoffice-status-text danger">Отказано плащане</span>
        </Grid>
      );
    case "None":
      return (
        variant === "queries" && (
          <Grid container alignItems="center" justifyContent="flex-start" direction="row">
            <Icon className="backoffice-status-icon warning">circle</Icon> <span className="backoffice-status-text warning">{txtNone}</span>
          </Grid>
        )
      );
    default:
      return;
  }
};

export default BackofficeStatus;
