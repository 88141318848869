import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, LinearProgress, TextField } from "@mui/material";
import _get from "lodash.get";
import WizardButton from "../Wizard/common/WizardButton";
import PageLayout from "../layouts/PageLayout";
import { getAdditionalPolicyFiles } from "../../services/PoliciesService";
import { connect } from "react-redux";
import withValidation from "../HOC/withValidation";
import "./send.documents.sass";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import withParams from "../HOC/withParams";

export const displayFileName = (file, policyDBId2) => {
  switch (file.type) {
    case "otherFile":
    case "receiptInsurer":
    case "greencard":
    case "installment":
    case "vdogDoubleSigned":
    case "statementDoubleSigned":
    case "declaration":
    case "otherFileSigned":
    case "annex":
    case "shipment":
      return file.name;
    case "identification":
      return "Идентификация";
    case "birthCertificate":
      return file.displayname;
    case "receipt":
      return "Разписка";
    case "vdog":
      return "Възлагателен договор";

    case "policy":
      if (policyDBId2 && file?.name?.includes("CSC_")) {
        return `Полица (каско)`;
      }
      if (policyDBId2 && file?.name?.includes("GO_")) {
        return `Полица (гражданска)`;
      }
      return "Полица";
    case "saleAgreement":
      return "Договор за продажба/Фактура";
    case "technicalCharacteristics":
      return "Технически характеристики";
    default:
      return file.name;
  }
};

const SendDocuments = props => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesChecked, setFilesChecked] = useState([]);

  const {
    filtering = ["policy", "otherFile", "installment"],
    ordering = ["policy", "otherFile", "installment"],
    includeHiddenFiles = false,
    withCheckboxes = false,
    withLayout = false,
    txtFields,
    title,
    allSelected,
    orderFuntion
  } = props;

  const policyDBId = _get(props, "params.policyDBId", _get(props, "policyDBId"));
  const policyDBId2 = _get(props, "params.policyDBId2", _get(props, "policyDBId2"));
  const defaultName = _get(props, "params.defaultName", _get(props, "defaultName"));

  useEffect(() => {
    setLoading(true);

    async function fetchData() {
      let allFiles;
      const response = await getAdditionalPolicyFiles(policyDBId);

      if (policyDBId2 && policyDBId2 !== "-") {
        const response2 = await getAdditionalPolicyFiles(policyDBId2);
        allFiles = [..._get(response, "data", []), ..._get(response2, "data", [])];
      } else {
        allFiles = _get(response, "data", []);
      }
      let filesChecked = [];
      let filteredFiles = [];
      filteredFiles = allFiles.filter(f => filtering.includes(f?.type) && (includeHiddenFiles || !f?.hidden));
      if (orderFuntion) {
        filteredFiles = orderFuntion(filteredFiles);
      } else {
        customSorting(filteredFiles, ordering);
      }
      const policyFile = filteredFiles.find(x => x.type === "policy");
      if (withCheckboxes && !allSelected) {
        if (policyFile) filesChecked = [policyFile];
        setFilesChecked(filesChecked);
      } else {
        setFilesChecked(filteredFiles);
      }
      setFiles(filteredFiles);
      setLoading(false);
    }

    fetchData();
  }, []);
  const handleSubmit = async () => {
    if (props?.handleValidation()) {
      await props.handleSubmit({
        policyDBId,
        email: _get(props, "formData.email"),
        additionalEmails: _get(props, "formData.additionalEmails"),
        files: filesChecked,
        policyDBIdCSC: policyDBId,
        policyDBIdGO: policyDBId2
      });
    }
  };
  const customSorting = (files, customOrder) => {
    let ordering = {}, // map for efficient lookup of sortIndex
      sortOrder = customOrder;
    for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;
    files.sort((a, b) => {
      return ordering[a.type] - ordering[b.type] || a.name.localeCompare(b.name);
    });
  };

  const handleChange = e => {
    let arr = [];
    const fileExists = filesChecked.find(x => x.name === e.target.name);
    if (fileExists && !e.target.checked) {
      arr = filesChecked.filter(x => x.name !== e.target.name);
      //remove file from filesChecked
    } else if (e.target.checked) {
      const fileToAdd = files.filter(x => x.name === e.target.name);
      if (fileToAdd) {
        arr = [...fileToAdd, ...filesChecked];
      }
    }
    setFilesChecked(arr);
  };
  const content = (
    <Grid container spacing={0} display="flex" justifyContent="center">
      <Grid item xs={8} lg={8}>
        <Box mb={5} display="flex" justifyContent="center">
          <span className="size-5">Клиент: {defaultName}</span>
        </Box>
        <Box mb={2}>
          {txtFields.map(f => (
            <TextField
              sx={{ mb: 1, mt: 1 }}
              key={f.name}
              placeholder={f.placeholder}
              fullWidth
              label={f.label}
              variant="outlined"
              size="small"
              name={f.name}
              multiline={!!f.multiline}
              rows={f.multiline ? f.rows : undefined}
              value={props?.formData[f.name]}
              onChange={props?.handleChange}
              helperText={props?.errors[f.name]}
              error={!!props?.errors[f.name]}
            />
          ))}
        </Box>

        {(loading || props?.loading) && <LinearProgress sx={{ mb: 2 }} />}
        {files?.map((file, index) =>
          withCheckboxes ? (
            <Grid container key={`grid-item-${index}`} justifyContent="space-between" className="send-documents-gray-box">
              <Grid item sm={12} sx={{ overflow: "hidden" }}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name={file?.name} onChange={e => handleChange(e)} checked={!!filesChecked.find(x => x?.name === file?.name)} />}
                    label={displayFileName(file, policyDBId2)}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          ) : (
            <div key={`grid-item-${index}`} className="pdf-wrapper vertical-align send-documents-gray-box">
              <PictureAsPdfIcon />
              <span>{displayFileName(file, policyDBId2)}</span>
            </div>
          )
        )}

        <WizardButton disabled={loading || props?.loading} btnText="Изпрати" handleSubmit={handleSubmit} loading={loading || props?.loading} />
      </Grid>
    </Grid>
  );
  if (withLayout)
    return (
      <PageLayout title={title || "Изпрати документи"} {...props}>
        {content}
      </PageLayout>
    );
  return content;
};

function mapStateToProps(state) {
  return {
    form: state.form
  };
}

export default connect(mapStateToProps)(withValidation(withParams(SendDocuments)));
